<template>
  <div>
    <div id="user-menu-anchor" class="user-menu-anchor">&nbsp;</div>
    <v-row justify="center">
      <v-col cols="12" lg="8">
        <new-perseu-card title="Sincronização de dados">
          <!-- <p v-show="canSync">
            Você possui <strong>{{ avaibleSpace }}</strong> MB de espaço livre e
            está utilizando <strong>{{ usageSpace }}</strong> MB no momento.
          </p>
          <p v-show="!canSync" class="font-weight-bold error--text">
            Seu navegador não é compatível para armazenar dados offline.
          </p> -->
          <p class="text-center mt-5 mb-0 font-weight-bold">
            Ultima sincronização: {{ lastUpdate | formatDateTime }}
          </p>
          <p class="text-center">
            Clique no botão abaixo para sincronizar os dados offline.
          </p>
          <div class="d-flex justify-center mt-5">
            <v-btn
              id="sync_btn"
              rounded
              large
              :disabled="!canSync"
              color="secondary"
              @click="sync"
            >
              <v-icon class="mr-1">sync</v-icon>
              Sincronizar
            </v-btn>
            <v-btn
              id="sync_btn"
              rounded
              large
              :disabled="!canSync"
              color="secondary"
              @click="finishSync"
            >
              <v-icon class="mr-1">sync</v-icon>
              finish
            </v-btn>
          </div>
        </new-perseu-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getLastConfig } from "@/services/local/config-service";

export default {
  data: () => ({
    lastUpdate: "Nenhuma sincronização realizada.",
    avaibleSpace: "",
    usageSpace: "",
    canSync: true,
    steps: [
      {
        element: "#sync_btn",
        popover: {
          title: "Sincronização",
          description:
            "Clique aqui para iniciar a sincronização (pode levar alguns minutos)",
          position: "bottom-center",
        },
      },
    ],
    menuSteps: [
      {
        element: "#user-menu-anchor",
        popover: {
          title: "Menu do usuário",
          description: "Clique aqui para acessar o menu do usuário",
          position: "left",
        },
      },
    ],
  }),
  async mounted() {
    await this.loadLastUpdate();
    this.showEstimatedQuota();
    this.showDriver();
  },
  methods: {
    async showEstimatedQuota() {
      if (navigator.storage && navigator.storage.estimate) {
        const estimation = await navigator.storage.estimate();
        console.log(`Quota: ${estimation.quota}`);
        console.log(`Usage: ${estimation.usage}`);
      } else {
        console.error("StorageManager not found");
      }
    },

    async sync() {
      try {
        await this.$store.dispatch("sync/download");
        this.$toasted.global.success({
          message: "Dados sincronizados com sucesso!",
        });
        this.loadLastUpdate();
      } catch (error) {
        this.$toasted.global.error({
          message: "Aconteceu algum erro ao sincronizar os dados!",
        });
      }
    },
    async loadLastUpdate() {
      const config = await getLastConfig();
      if (config) this.lastUpdate = config.lastUpdate;
      this.finishSync();
    },
    finishSync() {
      this.$vueJsDriver.showDriver(this.menuSteps);
    },
    showDriver() {
      this.$vueJsDriver.showDriver(this.steps);
    },
  },
};
</script>

<style>
.user-menu-anchor {
  position: absolute;
  top: -30px;
  right: -10px;
  width: 100px;
  height: 10px;
}
</style>
